<template>
  <v-container class="auth-wrapper align-center d-flex align-center my-16">
    <v-row>
      <v-col cols="12" xl="4" lg="6" md="8" sm="10" offset-xl="4" offset-lg="3" offset-md="2" offset-sm="1">
        <h2 class="auth-title">Вход</h2>
        <v-card width="100%" height="auto" class="pa-4 pa-lg-6 pa-xl-8 auth-card">
          <v-card-text class="pa-0">
            <v-form ref="loginForm">
              <v-text-field :height="inputHeight" solo hide-details class="auth-login" label="Логин" v-model="user.login" clearable></v-text-field>
              <v-text-field :height="inputHeight" solo hide-details class="auth-pass mt-xl-5 mt-4" label="Пароль" v-model="user.password"
                            :type="passwordShow ? 'text' : 'password'"
                            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="passwordShow = !passwordShow"
              ></v-text-field>
            </v-form>
            <v-btn class="button auth-btn mx-auto mt-xl-8 mt-lg-6 mt-4" width="80%" :height="inputHeight" color="#018ABE" @click="singIn()">Войти</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
<!--      <v-col cols="12" xl="4" lg="6" md="8" sm="10" offset-xl="4" offset-lg="3" offset-md="2" offset-sm="1">-->
<!--        <v-card width="100%" height="auto" class="py-xl-15 py-lg-12 py-9 px-xl-16 px-lg-13 px-10 auth-card">-->
<!--          <v-card-title class="pa-0 justify-center"><span class="auth-title">Регистрация в админку</span></v-card-title>-->
<!--          <v-card-text class="pa-0 mt-xl-8 mt-md-6 mt-4">-->
<!--            <v-form ref="registerForm">-->
<!--              <v-text-field class="auth-login" label="Логин" v-model="registerUser.login" clearable></v-text-field>-->
<!--              <v-text-field class="auth-pass" label="Пароль" v-model="registerUser.password"-->
<!--                            :type="registerPasswordShow ? 'text' : 'password'"-->
<!--                            :append-icon="registerPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"-->
<!--                            @click:append="registerPasswordShow = !registerPasswordShow"-->
<!--              ></v-text-field>-->
<!--            </v-form>-->
<!--            <v-btn class="mt-xl-8 mt-md-6 mt-4 py-6 auth-btn" width="100%" @click="singUp()">Зарегистрироваться</v-btn>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-col>-->
    </v-row>
  </v-container>
</template>

<script>
import User from '@/models/user'

export default {
  name: "AuthView",
  props: ['windowWidth'],
  data() {
    return {
      passwordShow: false,
      user: new User('', ''),
      registerPasswordShow: false,
      registerUser: new User('', '')
    }
  },
  computed: {
    inputHeight() {
      return this.windowWidth >= 1920 ? '64px' : '56px'
    }
  },
  methods: {
    async singIn() {
      const valid = this.$refs.loginForm.validate()

      if (valid) {
        this.$store.dispatch('auth/login', this.user).then(
            (response) => {
              if (response.error) {
                this.$store.dispatch('notify/newNotify', {type: 'error', text: response.message})
              } else {
                this.$store.dispatch('notify/newNotify', {type: 'success', text: 'Вы вошли'})
                this.user = new User('', '')
                this.$refs.loginForm.resetValidation()
                this.$router.push({name: 'admin'})
              }
            },
            () => {
              this.$store.dispatch('notify/newNotify', {type: 'error', text: 'Ошибка'})
            }
        )
      }
    },
    // async singUp() {
    //   const valid = this.$refs.registerForm.validate()
    //
    //   if (valid) {
    //     this.$store.dispatch('auth/register', this.registerUser).then(
    //         (response) => {
    //           if (response.error) {
    //             this.$store.dispatch('notify/newNotify', {type: 'error', text: response.message})
    //           } else {
    //             this.$store.dispatch('notify/newNotify', {type: 'success', text: 'Вы зарегистрировались'})
    //             this.user = new User('', '')
    //             this.$refs.loginForm.resetValidation()
    //           }
    //         },
    //         () => {
    //           this.$store.dispatch('notify/newNotify', {type: 'error', text: 'Ошибка'})
    //         }
    //     )
    //   }
    // },
  }
}
</script>

<style scoped lang="sass">
.auth
  &-card
    border-radius: 12px
    background: #F8F8F8
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1)
  &-title
    margin-bottom: 20px
    text-align: center
    font-size: 36px
  &-login
    font-size: 18px
    border-radius: 10px

  &-pass
    font-size: 18px
    border-radius: 10px

  &-btn
    font-size: 18px
    color: #ffffff
@media (max-width: 1263.99px)
  .auth
    &-title
      font-size: 32px
@media (max-width: 959.98px)
  .auth
    &-title
      font-size: 28px
@media (max-width: 599.98px)
  .auth
    &-title
      font-size: 24px
    &-login
      font-size: 20px
    &-pass
      font-size: 20px
    &-btn
      font-size: 16px
</style>
